import { IconButton, lighten, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { SnackbarContent } from "notistack";
import { createElement, forwardRef, useMemo } from "react";
import { useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useOurRouter } from "../../hooks/useOurRouter";
import { getQuestsUrl } from "../../utils/router";
import { Link } from "../Link";
import { GenericQuestConfig } from "./quests.types";
import { getGroupConfigFromQuestId, getNextQuestFromQuestId, isGroupComplete } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.logo.corn,
      borderRadius: theme.shape.borderRadius * 2.5,
      width: 300,
      padding: theme.spacing(1.5),
    },
    title: {
      paddingRight: theme.spacing(4),
    },
    close: {
      position: "absolute",
      top: theme.spacing(0.25),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    closeIcon: {
      color: theme.palette.common.black,
      width: 16,
      height: 16,
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    nextUp: {
      marginTop: theme.spacing(1),
    },
    nextUpItem: {
      backgroundColor: lighten(theme.colors.logo.corn, 0.5),
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      display: "flex",
      alignItems: "start",
      borderRadius: theme.shape.borderRadius * 2.5,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: lighten(theme.colors.logo.corn, 0.4),
      },
    },
    nextUpIcon: {
      width: 38,
      color: theme.colors.textGrey,
    },
    nextUpItemText: {
      marginLeft: theme.spacing(1),
    },
    groupMessage: {
      marginTop: theme.spacing(1)
    },
  }),
  {
    classNamePrefix: "QuestNotification",
  }
);

export type QuestNotificationJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestNotificationProps = {
  classes?: Partial<ClassNameMap<QuestNotificationJSSClassKey>>;
  className?: string;
  quest: GenericQuestConfig;
  onClose: () => void;
};

export const QuestNotification = forwardRef<HTMLDivElement, QuestNotificationProps>(
  ({ className, classes: extClasses, quest, onClose }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const router = useOurRouter();
    const { setActiveQuest } = useQuestsActions();
    const { quests } = useQuestsState();

    const nextQuest = useMemo(() => getNextQuestFromQuestId(quest.id), [quest.id]);
    const questGroup = useMemo(() => getGroupConfigFromQuestId(quest.id), [quest.id]);

    const groupComplete = useMemo(
      () => !!quests && !!questGroup && isGroupComplete(quests, questGroup.id),
      [quests, questGroup]
    );

    const handleStartNextQuest = useCallbackSafeRef(() => {
      if (!nextQuest) return;

      setActiveQuest({ group: nextQuest.groupId, quest: nextQuest.id, step: nextQuest.steps[0].id });

      if (nextQuest.entryPathname) {
        void router.push(nextQuest.entryPathname);
      }

      onClose();
    });

    const handleGroupCompleteClick = useCallbackSafeRef(() => {
      void router.push(getQuestsUrl());
      onClose();
    })

    return (
      <SnackbarContent ref={ref} className={clsx(classes.root, className)}>
        <IconButton aria-label="close" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.content}>
          <Typography className={classes.title} variant="body2">
            <b>{quest.title}</b> complete!
          </Typography>
          {!!nextQuest && (
            <div className={classes.nextUp} onClick={handleStartNextQuest}>
              <Typography variant="body2">
                <b>Next up</b>
              </Typography>
              <div className={classes.nextUpItem}>
                {createElement(quest.icon, { className: classes.nextUpIcon })}
                <div className={classes.nextUpItemText}>
                  <Typography variant="body2">
                    <b>{nextQuest.title}</b>
                  </Typography>
                  <Typography variant="body2">{nextQuest.description}</Typography>
                </div>
              </div>
            </div>
          )}
          {!nextQuest && !!questGroup && !!groupComplete && (
            <Typography variant="body2" className={classes.groupMessage}>
              You've completed all the tasks in <b>{questGroup.title}</b>, Great work!{" "}
              <Link onClick={handleGroupCompleteClick}>Keep on exploring.</Link>
            </Typography>
          )}
        </div>
      </SnackbarContent>
    );
  }
);
